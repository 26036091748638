import { ROUTES } from "../constants/routes"
import { isEmpty } from "lodash"
import {
  redirectTo,
  isBrowser,
  setLocalStorage,
  getLocalStorage,
  USER_COUNTRY,
  USER_POSTAL_CODE,
  NEW_SUBSCRIPTION_KEY,
  USER_INFO_KEY,
  CURRENT_COUNTRY,
  getSessionStorage,
  MEMBERSHIP_INFO,
  IS_MAGENTO,
  IS_REDIRECT_TO_SIGNUP,
  deleteLocalStorage,
} from "./localstorage"
import { navigate } from "gatsby"
import { getCreateAccountRoute, isActiveSubscription } from "./subscriptions"
import {
  getParamsFromURL,
  getCurrentUser,
  getBrowserTimezone,
  checkCurrentSub,
} from "./utilities"

import {
  updateUserCart,
  isExpiredOffer,
  getCartProduct,
  updateMembershipInfo,
} from "./carts"
import { GetUserRedirectSignedIn } from "../graphql/getUser"

// https://lesmillsinternational.atlassian.net/browse/LA-55
import uids from "@lesmills/gatsby-theme-common/src/constants/uids.json"
import offers_uids from "@lesmills/gatsby-theme-common/src/constants/offers-uids.json"
import reseller_uids from "@lesmills/gatsby-theme-common/src/constants/reseller-uids.json"

export const deleteCartInfo = (lang, handleError) => {
  updateUserCart(
    { product_handle: null },
    true,
    // navigate to membership after delete cart info
    () => navigate(ROUTES(lang).MEMBERSHIP),
    handleError
  )
}

// TODO: remove function as does not look like it is being used
export const handleUserNoSubscription = async (
  user,
  lang,
  handleErrorUpdateUser
) => {
  const {
    cartProductHandle,
    cartExpiryDate,
    cartOfferId,
    lmodSubscription,
    userEntitlements,
    addressCountry,
  } = user

  const { vimeoUserTicket } = userEntitlements?.LMOD || {}

  // AB2B-635: handle redirect to Vimeo page for user that has vimeoUserTicket but does not have lmodSubscription
  if (!lmodSubscription && vimeoUserTicket) {
    redirectTo(`${ROUTES().LMOD_CUSTOMER_WATCH}?ticket=${vimeoUserTicket}`)
    return
  }

  const product = getLocalStorage(NEW_SUBSCRIPTION_KEY)
  const { product_handle, isNZOffers, offerId } = product
  const isAffiliate = !!offerId
  const isDifferentCountry =
    (addressCountry && addressCountry.toLowerCase()) !==
    getSessionStorage(CURRENT_COUNTRY).toLowerCase()
  const isSelectedNewSubs =
    isAffiliate || // selected a offer
    (product_handle && cartProductHandle !== product_handle) || // selected new product
    (product_handle && isDifferentCountry) // selected new product in another country

  // Redirect user to Membership page in case no selected product
  if (!product_handle && !cartProductHandle) {
    navigate(ROUTES(lang).MEMBERSHIP)
    return
  }

  // reset cart if select offer
  // update cart if select another product in the same/ different country
  // then navigate to step payment
  if (isSelectedNewSubs) {
    updateUserCart(
      product,
      // https://lesmillsinternational.atlassian.net/browse/LA-1069
      // add flag isDifferentCountry to delete billing address
      isDifferentCountry,
      // navigate to step payment once finish update cart
      () => {
        const membershipInfo = getLocalStorage(MEMBERSHIP_INFO)

        // https://lesmillsinternational.atlassian.net/browse/LA-982
        // should update membership info if selected a lmnz offer
        if (isNZOffers && !isEmpty(membershipInfo)) {
          updateMembershipInfo(membershipInfo)
        }
        navigate(`${getCreateAccountRoute(isAffiliate, lang)}?step=payment`)
      },
      handleErrorUpdateUser
    )
  }

  // delete cart information if go to another country without selected new product before
  else if (isDifferentCountry) {
    deleteCartInfo(lang, handleErrorUpdateUser)
  }

  // Redirect affiliate user to check email if offer is expired
  else if (isExpiredOffer(cartExpiryDate)) {
    navigate(ROUTES(lang).CHECK_EMAIL)
  }
  // get cart product and navigate to step payment if no select new product or offer is not expired
  else {
    getCartProduct(
      user,
      () =>
        navigate(
          getCreateAccountRoute(isAffiliate || !!cartOfferId, lang) +
            "?step=payment"
        ),
      () => navigate(ROUTES(lang).MEMBERSHIP)
    )
  }
}

export const handleUserHasSubscription = (
  user,
  redirectURL,
  lang,
  handleErrorUpdateUser
) => {
  // Callback for user has sub, navigate to workout-plans or my-account
  if (isBrowser) {
    const userCountry = user.addressCountry || ""
    const userPostalCode = user.addressPostalCode

    userCountry && setLocalStorage(USER_COUNTRY, userCountry)
    userPostalCode && setLocalStorage(USER_POSTAL_CODE, userPostalCode)

    const { offerId, product_handle, offerExpiredDate } = getLocalStorage(
      NEW_SUBSCRIPTION_KEY
    )

    const isInvalidSub = !user.userEntitlements?.LMOD?.vimeoUserTicket
    const isInvalidOffer = !!offerId && isExpiredOffer(offerExpiredDate)
    // LA-2220: Redirect canceled account to step 2 if user select a sub before signing in
    // if user has valid sub or there is no valid sub in local then user got to my-account
    if (!isInvalidSub || !product_handle || isInvalidOffer) {
      redirectTo(redirectURL)
      return
    }

    const isDifferentCountry =
      userCountry.toLowerCase() !==
      getSessionStorage(CURRENT_COUNTRY).toLowerCase()

    // LA-1060: Reset address when cancelled account moves country
    updateUserCart(
      getLocalStorage(NEW_SUBSCRIPTION_KEY),
      isDifferentCountry,
      () => navigate(`${getCreateAccountRoute(!!offerId, lang)}?step=payment`),
      handleErrorUpdateUser
    )
  }
}

// https://lesmillsinternational.atlassian.net/browse/LA-55
export const isValidRepeatableRoute = pathname =>
  [...uids, ...offers_uids, ...reseller_uids].some(uid =>
    pathname.includes(uid)
  )

export const isValidRoute = pathname =>
  pathname.includes(ROUTES().SIGN_IN) ||
  pathname.includes(ROUTES().FORGOT_PASSWORD) ||
  pathname.includes(ROUTES().RESET_PASSWORD) ||
  pathname.includes(ROUTES().SALES_LANDING) ||
  pathname.includes(ROUTES().EDIT_PERSONAL_INFORMATION) ||
  pathname.includes(ROUTES().UPDATE_PAYMENT_DETAILS) ||
  pathname.includes(ROUTES().OFFERS) ||
  pathname.includes(ROUTES().EMAIL_NEWSLETTER) ||
  pathname.includes(ROUTES().CUSTOMER_ACCOUNT) ||
  pathname.includes(ROUTES().CREATE_ACCOUNT_AFFILIATE) ||
  pathname.includes(ROUTES().FAQ) ||
  pathname.includes(ROUTES().GETTING_STARTED) ||
  pathname.includes(ROUTES().CHECK_EMAIL) ||
  pathname.includes(ROUTES().COOKIE_POLICY) ||
  pathname.includes(ROUTES().NOT_FOUND) ||
  pathname.includes(ROUTES().TERMS_OF_USE) ||
  pathname.includes(ROUTES().PRIVACY_POLICY) ||
  pathname.includes(ROUTES().CHANGE_SUBSCRIPTION) ||
  pathname.includes(ROUTES().CREATE_ACCOUNT) ||
  pathname.includes(ROUTES().MEMBERSHIP) ||
  pathname.includes(ROUTES().CHALLENGES) ||
  pathname.includes(ROUTES().PROMOTION) ||
  pathname.includes(ROUTES().UNSUBSCRIBE_CHALLENGES) ||
  isValidRepeatableRoute(pathname) || // https://lesmillsinternational.atlassian.net/browse/LA-55
  pathname.includes(ROUTES().MAINTENANCE)

// These pages are shared pages between Axii and Magento, are still accessible to everyone
// All countries can access, don't except any
export const areCountriesSpecificPages = (pathname, lang) =>
  pathname.includes(ROUTES().CREATE_ACCOUNT) ||
  pathname.includes(ROUTES().CREATE_ACCOUNT_AFFILIATE) ||
  pathname.includes(ROUTES().MEMBERSHIP) ||
  pathname.includes(ROUTES().PROMOTION) ||
  pathname.includes(ROUTES().OFFERS) ||
  pathname === ROUTES(lang).SALES_LANDING ||
  pathname.includes(ROUTES().MEMBER_DISCOUNT) ||
  pathname.includes(ROUTES().NZ_OFFERS) ||
  isValidRepeatableRoute(pathname) // check for marketing SLP, personalized offers, reseller sign-up

export const checkToRedirectSignedIn = async (user, lang, setIsLogging) => {
  const redirectToURL = decodeURIComponent(
    getParamsFromURL("redirect_to") || ""
  )
  const vimeoURL = `https://watch.${process.env.GATSBY_LMOD_DOMAIN}`
  const currentUser = await getCurrentUser(
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    GetUserRedirectSignedIn
  )
  const {
    lmodSubscription,
    lmodEntitlement,
    userEntitlements,
    vimeoUserId,
  } = currentUser

  const { vimeoUserTicket } = userEntitlements?.LMOD || {}

  // Set browser timezone to segment
  if (setIsLogging) {
    if (currentUser && isBrowser && window.analytics) {
      window.analytics.identify(`${vimeoUserId}`, {
        timezone: getBrowserTimezone(),
      })
    }
  }

  const isEntitled = !!lmodEntitlement

  const isFromSignUpPage = getLocalStorage(IS_REDIRECT_TO_SIGNUP) === "true"

  // Redirect handle for the sign up journey
  // Seperating this logic to reinstate the old German sign up jorney behaviour LPG-246
  if (isFromSignUpPage) {
    if (!lmodSubscription || !isEntitled) {
      redirectTo(redirectToURL)
    } else {
      redirectTo(ROUTES(lang).LMOD_EXPLORE)
    }

    deleteLocalStorage(IS_REDIRECT_TO_SIGNUP)
    return
  }

  // LME-246: handle SSO redirection flow
  // https://lesmillsinternational.atlassian.net/wiki/spaces/LMODPLAN/pages/773325215/Central+Login+Page+and+Routing+Component#CentralLoginPageandRoutingComponent-SSODiagramwithoutMagento

  // Redirect unfinished cart process user to membership page
  if (!lmodSubscription) {
    navigate(ROUTES(lang).MEMBERSHIP)
    return
  }

  // Redirect sign-in without redirect_to param to Explore page(active subscription) or Customer account page(no active subscription)
  // refer: https://lesmillsinternational.atlassian.net/browse/LME-1439
  if (!redirectToURL) {
    if (isEntitled) {
      redirectTo(ROUTES(lang).LMOD_EXPLORE)
    } else {
      navigate(ROUTES(lang).CUSTOMER_ACCOUNT)
    }
    return
  }

  if (redirectToURL.includes(vimeoURL)) {
    // Sign-in Vimeo in case user has valid vimeo account
    if (isEntitled) {
      redirectTo(`${redirectToURL}?ticket=${vimeoUserTicket}`)
    } else {
      navigate(ROUTES(lang).MEMBERSHIP)
    }
  } else {
    if (isEntitled) {
      redirectTo(redirectToURL)
    } else {
      navigate(ROUTES(lang).CUSTOMER_ACCOUNT)
    }
  }
}

export const addTicketToExternalLink = (
  url,
  // hardcode lmod domain for links on Prismic
  lmodDomain = "lmplus-test.com"
) => {
  const { lmodSubscription, userEntitlements } = getLocalStorage(USER_INFO_KEY)
  const { vimeoUserTicket } = userEntitlements?.LMOD || {}
  const isMagentoUser = getLocalStorage(IS_MAGENTO)
  const isVimeoPage = url.includes(`watch.${lmodDomain}`)
  const { state } = lmodSubscription || {}
  let param = ""

  if (isVimeoPage && vimeoUserTicket) {
    const hasValidSubscription =
      isMagentoUser === true ? true : isActiveSubscription(state)
    param = hasValidSubscription ? `?ticket=${vimeoUserTicket}` : ""
  }

  return url + param
}

export const handleUserNotLoggedIn = (isAffiliate, lang, state) => {
  const step = getParamsFromURL("step")
  const isNotOnStep1 = !!step
  const isSelectedSubscription = !!getLocalStorage(NEW_SUBSCRIPTION_KEY)
    .product_handle

  if (!isSelectedSubscription) {
    // If there is no sub, Affiliate user should be navigated to Check Email page
    navigate(isAffiliate ? ROUTES(lang).CHECK_EMAIL : ROUTES(lang).MEMBERSHIP)
    return
  } else if (isNotOnStep1 && !state.email) {
    // if user didn't log in and select 1 product => redirect to step 1
    navigate(getCreateAccountRoute(isAffiliate, lang))
  }
}

export const handleUserNoSubscriptionForCreateAccount = (
  currentUser,
  state,
  lang,
  isAffiliate
) => {
  const step = getParamsFromURL("step")

  // case logged in
  if (Object.keys(currentUser).length > 0) {
    // Redirect user to Membership page in case no selected product
    const product = getLocalStorage(NEW_SUBSCRIPTION_KEY)
    if (!product.product_handle && !currentUser.cartProductHandle) {
      navigate(ROUTES(lang).MEMBERSHIP)
      return
    }

    // navigate to check email if offer expired
    if (isExpiredOffer(currentUser.cartExpiryDate)) {
      navigate(ROUTES(lang).CHECK_EMAIL)
    } else if (
      ((!currentUser.chargifyPaymentProfile && !currentUser.paymentMethod) ||
        !currentUser.addressCountry) &&
      step !== "payment"
    ) {
      // navigate to step payment if there is not paymentMethod
      navigate(`${getCreateAccountRoute(isAffiliate, lang)}?step=payment`)
    }
  } else {
    // case not logged in
    handleUserNotLoggedIn(isAffiliate, lang, state)
  }
}

export const checkToRedirectCreateAccount = (
  user,
  state,
  lang,
  isAffiliate = false
) => {
  checkCurrentSub(
    // withSubCallBack
    () => {
      navigate(ROUTES(lang).CUSTOMER_ACCOUNT)
      return
    },
    currentUser =>
      handleUserNoSubscriptionForCreateAccount(
        currentUser,
        state,
        lang,
        isAffiliate
      ),
    user
  )
}

// Check whether pages are redirection public pages or not
export const checkRedirectionPublicPage = pathname =>
  pathname.includes(ROUTES().SCHEDULES) ||
  pathname.includes(ROUTES().EQUIPMENT) ||
  pathname.includes(ROUTES().SIGNUP_GIFT_SUBSCRIPTION) ||
  pathname.includes(ROUTES().LMOD_COOKIE_POLICY) ||
  pathname.includes(ROUTES().SIGN_OUT)

export const checkMagentoUser = response =>
  !isEmpty(response) &&
  response.attributes &&
  response.attributes["custom:magento_entity_id"]
